import { Input, message } from 'antd'
import styles from "../../css/login.module.scss";
import leftImg from '../../img/lefttitle.png'
import passwordPrefix from '../../img/passwordPrefix.png'
import accountPrefix from '../../img/accountPrefix.png'
import { useState } from 'react';
import { userLogin, getIsAnswer } from "../../utils/request";
import { useNavigate } from "react-router-dom";

export function AccountPrefix() {
  return (
    <img src={accountPrefix} alt="account" className={styles.accountPrefix} />
  );
}
export function PasswordPrefix() {
  return (
    <img
      src={passwordPrefix}
      alt="password"
      className={styles.passwordPrefix}
    />
  );
}

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function getAnswer() {
    getIsAnswer().then((res) => {
      if (res.data) {
        navigate("/user", { replace: true });
      } else {
        navigate("/user/survey", { replace: true });
      }
    });
  }

  function doLogin() {
    if (!email || !password) {
      message.warning(
        !email ? "Please enter your email" : "Please enter your password"
      );
      return;
    }
    if (loading) return;
    setLoading(true);
    userLogin({ email, password })
      .then((res) => {
        if (res.code === 0) {
          getAnswer();
        }
      })
      .catch((e) => {
        console.log(e);
      }).finally(() => {
        setLoading(false);
      });
  }
  return (
    <div className={styles.bg}>
      <div className={styles.login}>
        <div className={styles.logWrap}>
          <div className={styles.loginLeft}>
            <img src={leftImg} alt="read" className={styles.lefttitle} />
            <p className={styles.leftcontent}>
              学生们不仅能沉浸在精彩纷呈的故事与知识海洋中，还能在享受阅读乐趣的同时，参与互动问答挑战。
            </p>
          </div>
          <div className={styles.loginRight}>
            <p className={styles.loginRightTitle}>登录账号</p>
            <Input
              placeholder="账号"
              className={styles.accountInput}
              prefix={<AccountPrefix />}
              value={email}
              onPressEnter={doLogin}
              onChange={(e) => setEmail(e.target.value)}
            ></Input>
            <Input
              placeholder="密码"
              className={styles.passwordInput}
              type="password"
              prefix={<PasswordPrefix />}
              value={password}
              onPressEnter={doLogin}
              onChange={(e) => setPassword(e.target.value)}
            ></Input>
            <div className={styles.loginBtn} onClick={doLogin}>
              登录
            </div>
          </div>
        </div>
      </div>
      <div className={styles.boy}></div>
      <div className={styles.girl}></div>
      <div className={styles.copyright}>粤ICP备2024306820号-1</div>
    </div>
  );
}
