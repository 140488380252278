import { observer } from "mobx-react";
import {
  getBook,
  getBookChallenges,
  startChallenge,
  getBookEvaluate,
} from "../../utils/request";
import styles from "../../css/UserChallengeTransfer.module.scss";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Stars from "../../components/stars";
import pencil from "../../img/pencil.png";
import trophy from "../../img/trophy.png";
import { LayoutContext } from "../auth";

function UserChallengeTransfer() {
  const [book, setBook] = useState({});
  const [finishedNum, setFinishedNum] = useState(0);
  const [bookMark, setBookMark] = useState(0);
  const [bookVote, setBookVote] = useState(0);
  const { id } = useParams();
  const { setHead } = useContext(LayoutContext);
  const navigate = useNavigate();

  const getBookInfo = useCallback(() => {
    getBook(id).then((res) => {
      setBook(res.data);
    });
  }, [id]);

  const getChallenges = useCallback(() => {
    getBookChallenges(id).then((res) => {
      setFinishedNum(res.data.length);
    });
  }, [id]);

  const getEvaluate = useCallback(() => {
    getBookEvaluate(id).then((res) => {
      setBookMark(res.data.mark);
      setBookVote(res.data.vote);
    });
  }, [id]);

  useEffect(() => {
    getBookInfo();
    getChallenges();
    getEvaluate();
    setHead({
      needMenu: false,
    });
  }, [setHead, getBookInfo, getChallenges, getEvaluate]);
  function goRead() {
    const pdfUrl = encodeURIComponent(book.filePath);
    const url = `https://pdf.happyreading.cc/ebook.html?url=${pdfUrl}`;
    window.open(url, "_blank");
  }
  function goChallenge() {
    startChallenge({ bookId: id }).then((res) => {
      if (finishedNum < 3) {
        navigate(`/user/challenge/${id}/${res.data.id}`);
      } else {
        navigate(`/user/challengefinish/${id}/${res.data.id}`);
      }
    });
  }
  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <div className={styles.book}>
          <div className={styles.bookCover}>
            <img src={book.cover} alt="cover"></img>
          </div>
          <p className={styles.bookTitle}>{book.title}</p>
          <Stars value={bookMark} count={bookMark} disabled></Stars>
          <p className={styles.bookVote}>票数：{bookVote}</p>
          <div className={styles.bookBtn} onClick={goRead}>
            去阅读
          </div>
        </div>
        <div className={styles.challenge}>
          <div className={styles.challengeImg}>
            <img src={trophy} alt="trophy"></img>
          </div>
          <p className={styles.challengeInfo}>挑战3次（计算最高分）</p>
          <p className={styles.challengeInfo}>第二次挑战打9折</p>
          <p className={styles.challengeInfo}>第三次挑战打8折</p>
          <div className={styles.challengeBtn} onClick={goChallenge}>
            <span>开始挑战（{finishedNum}/3）</span>
            <img src={pencil} alt="pencil"></img>
          </div>
        </div>
      </div>
      <div className={styles.bg1}></div>
      <div className={styles.bg2}></div>
    </div>
  );
}
export default observer(UserChallengeTransfer);
