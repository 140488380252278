import { observer } from "mobx-react";
import { getBook } from "../../utils/request";
import styles from "../../css/UserRead.module.scss";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import PdfPreview from "../../components/pdfPreview";

function UserRead() {
  const [book, setBook] = useState({});
  const { id } = useParams();

  const getBookInfo = useCallback(() => {
    getBook(id).then((res) => {
      setBook(res.data);
    });
  }, [id]);

  useEffect(() => {
    getBookInfo();
  }, [getBookInfo]);

  return (
    <div className={styles.page}>
      <div className={styles.book}>
        <div className={styles.bookCover}>
          <img src={book.cover} alt="cover"></img>
        </div>
        <div className={styles.bookContent}>
          <PdfPreview file={book.filePath}></PdfPreview>
        </div>
      </div>
    </div>
  );
}
export default observer(UserRead);
