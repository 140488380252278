import { createBrowserRouter } from 'react-router-dom';
import Root from './Root'
import Login from "./view/login/index";
import { PrivateRoute } from "./view/auth/index"; // 引入权限控制组件
import UserHome from "./view/userHome/index";
import AdminHome from './view/adminHome';
import AdminBookList from "./view/adminBook/list";
import AdminBookCreate from "./view/adminBook/create";
import AdminBookDrafts from "./view/adminBook/drafts";
import AdminReactions from "./view/adminReactions";
import AdminReactionDetail from "./view/adminReactionDetail";
import AdminCollect from "./view/adminCollect";
import AdminSurvey from "./view/adminSurvey";
import AdminFeedback from "./view/adminFeedback";
import AdminLogin from "./view/adminLogin";
import UserChallengeTransfer from "./view/UserChallengeTransfer";
import UserChallenge from "./view/UserChallenge";
import UserRead from "./view/UserRead";
import UserChallengeFinish from "./view/UserChallengeFinish";
import UserReaction from "./view/UserReaction";
import UserGrade from "./view/userGrade";
import UserPerson from "./view/userPerson";
import UserTrend from "./view/userTrend";
import UserFeedBack from "./view/userFeedBack";
import UserSurvey from "./view/userSurvey";

const routes = [
  {
    path: "/",
    element: <Root />,
    errorElement: <h1>404 Not Found</h1>,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "adminlogin",
        element: <AdminLogin />,
      },
      {
        path: "admin",
        element: <PrivateRoute admin={true} />,
        children: [
          {
            path: "",
            element: <AdminHome />,
          },
          {
            path: "book",
            element: <AdminBookList />,
          },
          {
            path: "create/:id?",
            element: <AdminBookCreate />,
          },
          {
            path: "drafts",
            element: <AdminBookDrafts />,
          },
          {
            path: "reactions",
            element: <AdminReactions />,
          },
          {
            path: "reactiondetail/:id",
            element: <AdminReactionDetail />,
          },
          {
            path: "collect",
            element: <AdminCollect />,
          },
          {
            path: "survey",
            element: <AdminSurvey />,
          },
          {
            path: "feedback",
            element: <AdminFeedback />,
          },
        ],
      },
      {
        path: "user",
        element: <PrivateRoute />,
        children: [
          {
            path: "",
            element: <UserHome />,
          },
          {
            path: "survey",
            element: <UserSurvey />,
          },
          {
            path: "grade",
            element: <UserGrade />,
          },
          {
            path: "trend",
            element: <UserTrend />,
          },
          {
            path: "person",
            element: <UserPerson />,
          },
          {
            path: "feedback",
            element: <UserFeedBack />,
          },
          {
            path: "challengeTransfer/:id",
            element: <UserChallengeTransfer />,
          },
          {
            path: "challenge/:id/:challengeId",
            element: <UserChallenge />,
          },
          {
            path: "challengefinish/:id/:challengeId",
            element: <UserChallengeFinish />,
            loader: () => ({ needHead: false }),
          },
          {
            path: "reaction/:id/:challengeId",
            element: <UserReaction />,
            loader: () => ({ needHead: false }),
          },
          {
            path: "read/:id",
            element: <UserRead />,
            loader: () => ({ needHead: false }),
          },
        ],
      },
    ],
  },
];

export default createBrowserRouter(routes);