import { observer } from "mobx-react";
import { getReactions } from "../../utils/request";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "../../css/adminReactions.module.scss";
import { useNavigate } from "react-router";
import { LayoutContext } from "../auth";

function AdminReactions() {
  const [ratedReactions, setRatedReactions] = useState([]);
  const [ratedCount, setRatedCount] = useState(0);
  const [unratedReactions, setUnratedReactions] = useState([]);
  const [unratedCount, setUnratedCount] = useState(0);

  const navigate = useNavigate();
  const { setHead } = useContext(LayoutContext);
  const getAllReactions = useCallback(() => {
    getReactions().then((res) => {
      setRatedReactions(res.data.rated.reactions);
      setUnratedReactions(res.data.unrated.reactions);
      setRatedCount(res.data.rated.count);
      setUnratedCount(res.data.unrated.count);
    });
  }, []);

  useEffect(() => {
    getAllReactions();
    setHead({
      title: "读后感打分",
    });
  }, [getAllReactions, setHead]);
  function onItemClick(id) {
    navigate(`/admin/reactiondetail/${id}`);
  }
  return (
    <div className={styles.page}>
      <div className={styles.unrated}>
        <p className={styles.title}>
          未打分<span>{unratedCount}</span>
        </p>
        <div className={styles.reactions}>
          {unratedReactions.map((item) => (
            <div
              key={item.id}
              className={styles.reactionItem}
              onClick={() => onItemClick(item.id)}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.rated}>
        <p className={styles.title}>
          已打分<span>{ratedCount}</span>
        </p>
        <div className={styles.reactions}>
          {ratedReactions.map((item) => (
            <div
              key={item.id}
              className={styles.reactionItem}
              onClick={() => onItemClick(item.id)}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default observer(AdminReactions);
