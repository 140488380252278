import { observer } from "mobx-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "../auth";
import { Button, Table } from "antd";
import { columns } from "./const";
import { downloadBlob } from "../../utils/utils";
import {
  getSemesterStatis,
  downloadSemesterStastic,
} from "../../utils/request";
import styles from "../../css/adminCollect.module.scss";

function AdminCollect() {
  const { setHead } = useContext(LayoutContext);
  const [datas, setDatas] = useState([]);

  const getDatas = useCallback(() => {
    getSemesterStatis().then((res) => {
      setDatas(res.data);
    });
  }, []);

  useEffect(() => {
    setHead({
      title: "数据收集",
    });
    getDatas();
  }, [setHead, getDatas]);
  function downloadExcel() {
    downloadSemesterStastic().then((res) => {
      downloadBlob(res, "数据收集");
    });
  }
  return (
    <div className={styles.page}>
      <div className={styles.pageWrap}>
        <Table pagination={false} columns={columns} dataSource={datas} />
        <div className={styles.actions}>
          <Button type="primary" onClick={downloadExcel}>
            导出Excel表格
          </Button>
        </div>
      </div>
    </div>
  );
}
export default observer(AdminCollect);
