import { useEffect, useRef, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { Button } from "antd";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const styles = {
  documentStyle: {
    position: "relative",
    height: "100%",
  },
  pageStyle: {
    height: "120px",
    boxShadow: "0 0 8px rgba(0, 0, 0, 0.5)",
  },
  actions: {
    position: "absolute",
    bottom: "20px",
    right: "20px",
    zIndex: 5,
  },
};

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();
const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};
function PdfPreview({ file }) {
  const [numPages, setNumPages] = useState();
  const [pageHeight, setPageHeight] = useState();
  const [pageWidth, setPageWidth] = useState(650);
  const [currentPage, setCurrentPage] = useState(1);
  const documentRef = useRef(null);

  useEffect(() => {
    if (documentRef.current) {
      setPageWidth(documentRef.current.offsetWidth);
      setPageHeight(documentRef.current.offsetHeight);
    }
  }, []);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  function nextPage() {
    if (currentPage >= numPages) return;
    setCurrentPage((pre) => {
      return pre + 1;
    });
    documentRef.current.scrollTo({
      top: documentRef.current.scrollTop + pageHeight,
      behavior: "smooth",
    });
  }

  function prevPage() {
    if (currentPage <= 1) return;
    setCurrentPage((pre) => {
      return pre - 1;
    });
    documentRef.current.scrollTo({
      top: documentRef.current.scrollTop - pageHeight,
      behavior: "smooth",
    });
  }

  return (
    <div style={styles.documentStyle}>
      <div ref={documentRef} style={{ overflow: "auto", height: "100%" }}>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          {Array.from(new Array(numPages), (_el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={pageWidth}
              style={styles.pageStyle}
            />
          ))}
        </Document>
      </div>
      <div style={styles.actions}>
        <Button
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: "none",
          }}
          onClick={prevPage}
        >
          上一页
        </Button>
        <Button
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          onClick={nextPage}
        >
          下一页
        </Button>
      </div>
    </div>
  );
}
export default PdfPreview;
