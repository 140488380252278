import { observer } from "mobx-react";
import styles from "../../css/userFeedBack.module.scss";
import { Input, message, Modal } from "antd";
import { addFeedBack } from "../../utils/request";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../auth";

function UserFeedBack() {
  const [content, setContent] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { setHead } = useContext(LayoutContext);
  const placeholder = `同学们好～
有什么意见都可以反馈给老师哦～
点此输入`;

  useEffect(() => {
    setHead({
      needMenu: true,
    });
  }, [setHead]);

  function submit() {
    if (!content) {
      message.warning("请输入反馈内容");
      return;
    }
    addFeedBack({
      content,
    }).then(() => {
      setIsOpen(true);
      message.success("提交成功");
    });
  }
  return (
    <div className={styles.page}>
      <div className={styles.pageWrap}>
        <Input.TextArea
          onChange={(e) => setContent(e.target.value)}
          maxLength={1000}
          placeholder={placeholder}
        />
        <div className={styles.submitBtn} onClick={submit}>
          提交
        </div>
      </div>
      <div className={styles.wrapLeftBg}></div>
      <div className={styles.wrapRightBg}></div>
      <Modal
        open={isOpen}
        footer={null}
        closable={false}
        wrapClassName={styles.modalContent2}
        width={945}
        onCancel={() => setIsOpen(false)}
        style={{ top: "228px" }}
      >
        <div>
          <p className={styles.feedMsg}>感谢同学的反馈</p>
          <p className={styles.feedMsg}>老师收到后会仔细查看的</p>
          <div className={styles.feedBtn} onClick={() => setIsOpen(false)}>
            好的
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default observer(UserFeedBack);
