import { observer } from "mobx-react";
import styles from "../css/userHead.module.scss";
import userLogo from "../img/userLogo.png";
import userMenu1 from "../img/userMenu1.png";
import userMenu2 from "../img/userMenu2.png";
import userMenu3 from "../img/userMenu3.png";
import userMenu4 from "../img/userMenu4.png";
import userMenu5 from "../img/userMenu5.png";
import userMenu1Active from "../img/userMenu1Active.png";
import userMenu2Active from "../img/userMenu2Active.png";
import userMenu3Active from "../img/userMenu3Active.png";
import userMenu4Active from "../img/userMenu4Active.png";
import userMenu5Active from "../img/userMenu5Active.png";
import { useCallback, useEffect, useState } from "react";
import userStore from "../store/userStore";
import { useLocation, useNavigate } from "react-router";
const Menus = [
  {
    default: userMenu1,
    active: userMenu1Active,
    route: "/user",
  },
  {
    default: userMenu2,
    active: userMenu2Active,
    route: "/user/grade",
  },
  {
    default: userMenu3,
    active: userMenu3Active,
    route: "/user/trend",
  },
  {
    default: userMenu4,
    active: userMenu4Active,
    route: "/user/person",
  },
  {
    default: userMenu5,
    active: userMenu5Active,
    route: "/user/feedback",
  },
];

function UserHead({ needMenu = true }) {
  const [active, setActive] = useState(0);
  const userProfile = userStore.profile;
  const navigate = useNavigate();
  const location = useLocation();

  const restoreActive = useCallback(() => {
    const index = Menus.findIndex((k) => k.route === location.pathname);
    setActive(index);
  }, [location]);

  useEffect(() => {
    restoreActive();
    userStore.refresh();
  }, [restoreActive]);

  function onMenuChange(item, index) {
    if (location.pathname === item.route) return;
    setActive(index);
    navigate(item.route);
  }
  function onLogout() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  function handleHome() {
    navigate("/user");
  }
  return (
    <div className={styles.userHead}>
      <div className={styles.headLeft}>
        <img className={styles.userLogo} src={userLogo} alt="logo" onClick={handleHome}></img>
        {needMenu && (
          <div className={styles.userMenus}>
            {Menus.map((k, i) => (
              <div className={styles.menuItem} key={i}>
                <img
                  src={active === i ? k.active : k.default}
                  alt="menu"
                  onClick={() => onMenuChange(k, i)}
                ></img>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.headRight}>
        <span className={styles.userName}>欢迎，{userProfile?.username}</span>
        <div className={styles.logout} onClick={onLogout}>
          登出
        </div>
      </div>
    </div>
  );
}
export default observer(UserHead);
